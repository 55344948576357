import { render, staticRenderFns } from "./HomeBoxFigures.vue?vue&type=template&id=9c2d5742&scoped=true&"
import script from "./HomeBoxFigures.vue?vue&type=script&lang=js&"
export * from "./HomeBoxFigures.vue?vue&type=script&lang=js&"
import style0 from "./HomeBoxFigures.vue?vue&type=style&index=0&id=9c2d5742&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c2d5742",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../shared/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VRow,VSheet,VSkeletonLoader})
